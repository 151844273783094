<template>
  <div id="page">
    <div class="nav">
      <ul>
        <li v-for="(item,index) in headernav" :key="index"
        @click="this.$router.push(item.path)">
          {{item.text}}
        </li>
      </ul>
      <div id="search">
        <div class="logosearch">
          <div id="logo">
            <img src="../../assets/logo2.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <div id="content">
      <div class="message_body">
        <h1>确认收货信息</h1>
        <div class="show_mess">

          <div class="mess_title">
            <ul class="show_ul">
              <li class="show_li li_name">商品信息</li>
              <li class="show_li li_size">商品规格</li>
              <li class="show_li li_price">单价</li>
              <li class="show_li li_quantity">数量</li>
              <li class="show_li li_total">总价</li>
            </ul>
          </div>

          <div class="shop_mess">
            <ul class="show_ul">
              <li class="show_li li_name">
                <div class="namebody">
                  <img :src="shopdata.pmsResaleProduct.productPic" alt="">
                  <div class="name_time">
                    <p>{{shopdata.pmsResaleProduct.productName}}</p>
                    <span>{{shopdata.pmsResaleProduct.takeProductTime.substring(0,10)}}</span>
                  </div>
                </div>
              </li>
              <li class="show_li li_size">
                {{shopdata.pmsSkuStock.spData.length>=2
                ?shopdata.pmsSkuStock.spData[1].value:
                shopdata.pmsSkuStock.spData[0].value}}
                </li>
              <li class="show_li li_price">
                {{shopdata.pmsProduct.currencySymbol+
                shopdata.pmsResaleProduct.resalePrice+
                '/'+shopdata.pmsProduct.unit}}
                </li>
              <li class="show_li li_quantity">
                <el-input-number
                  v-model="quantity.value"
                  size="small"
                  :step="shopdata.pmsProduct.minIncr"
                  :min="quantity.min>quantity.max ? quantity.max : quantity.min"
                  :max="quantity.max"/>
              </li>
              <li class="show_li li_total">
                {{
                  shopdata.pmsProduct.currencySymbol+(shopdata.pmsResaleProduct.resalePrice * quantity.value)
                }}</li>
            </ul>
          </div>

          <!-- <div class="shop_type">
            <span class="type_title">提货类型</span>
            <div class="radios">
              <el-radio v-model="shopsite.radio" :label="1">物流</el-radio>
              <el-radio v-model="shopsite.radio" :label="0">自提</el-radio>
            </div>
          </div> -->
          <!-- <div v-if="shopsite.radio=='1'?true:false"
           class="shop_site">
            <wu-liu :wuliumes="wuliumessage"></wu-liu>
          </div> -->

          <div class="shop_invoice">
            <Invoice :invoicemsg="invoicemessage"
             @invoiceData="(Data)=>{this.invoiceGoData=Data}" />
          </div>

          <div class="tishi">
            <p>
              <el-icon><warning-filled /></el-icon>
              <span>请务必在规定时间内进行预提</span>
            </p>
            <div class="tiaoyue">
              <el-checkbox  v-model="checkedTiaoYue" size="mini">
                我已阅读并同意
                <span class="tiaokuan" @click="readContract(2)">《购销合同》</span>、
                <span class="tiaokuan" @click="readContract(4)">《贸易规则》</span>、
                <span class="tiaokuan" @click="readContract(5)">《质量标准》</span>
              </el-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="total_price">
        <div class="price_body">
          <div class="price_title">
            总计
          </div>

          <div class="price_show">
            <el-descriptions >
              <el-descriptions-item label="应付总金额：">
                {{comPrice}}
              </el-descriptions-item>
              <el-descriptions-item label="应付定金：">
                {{shopdata.pmsProduct.currencySymbol+(comPrice/100*shopdata.pmsProduct.prePayRate)}}
              </el-descriptions-item>
              <!-- <el-descriptions-item
              label="尾款联系方式：">
                <div class="phonenumshow">
                  <input type="text">
                  {{95511}}
                  <span @click="changephone">修改</span>
                </div>
              </el-descriptions-item> -->
            </el-descriptions>
            <div class="btn_submit">
              <el-button :disabled="!checkedTiaoYue"
               @click="submitData"
               :loading="loading"
               size="medium">提交订单</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="foot">
      <Footer/>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
// import { useRoute } from 'vue-router'

// import WuLiu from './WuLiu.vue'
import Invoice from './Invoice.vue'
import Footer from '../index/Footer.vue'
import { WarningFilled } from '@element-plus/icons'

import { getStore, setStore } from '../../tools/storage'
import emitter from '../../tools/bus.js'
import http from '../../api/http.js'
import moment from 'moment'
import { ElMessage } from 'element-plus'

const headernav = reactive([
  { text: '首页', path: '/' },
  { text: '登录', path: '/loginandregister' },
  { text: '移动端', path: '' }
])

export default {
  components: { Invoice, WarningFilled, Footer },
  name: 'AccoutsResell',
  data () {
    return {
      shopdata: reactive({}),
      quantity: reactive({
        value: 1,
        max: 10,
        min: 1,
        step: 2
      }),
      shopsite: {
        radio: 1,
        time1: ''
      },
      timeYushow: [], // 预提日期
      // wuliumessage: [], // 收货地址信息
      // wiliuChose: 0, // 选择的第几个地址
      invoicemessage: [], // 发票信息
      invoiceChose: 0, // 选定的发票
      // posturl: '', // 请求接口地址

      loading: false,
      checkedTiaoYue: ref(false)
    }
  },
  computed: {
    comPrice: {
      get: function () {
        const mount = this.shopdata.pmsResaleProduct.resalePrice *
        this.quantity.value +
        // this.priceList.freightCharge +
        this.shopdata.payAmount
        return mount
      }
    }
  },
  methods: {
    submitData () {
      const loadingpage = this.$loading({
        lock: true,
        text: '生成订单中'
      })
      // 转售订单 生成
      const postdata = {
        invoiceId: this.invoiceChose, // 发票id
        productId: this.shopdata.pmsResaleProduct.id, // 转售商品id
        productQuantity: this.quantity.value, // 购买数量
        resaleFlag: 0, // 商品类型 0：转售，1：非转售
        productSkuId: this.shopdata.pmsSkuStock.id, // skuID
        payType: null, // 支付方式
        orderType: 2, // 订单类型
        resaleOrderSn: this.shopdata.pmsResaleProduct.orderSn, // 转售订单号
        resaleUserId: this.shopdata.pmsResaleProduct.resaleUserId, // 转售人id
        takeGoodsType: this.shopsite.radio, // 提货类型
        takeProductAddressId: 0, // 提货地点
        confirmContract: 0,
        takeGoodsTime: this.shopdata.pmsResaleProduct.takeProductTime // 提货时间
        // presaleWayId: '' // 预提方式id
      }
      if (this.shopsite.radio === 1) {
        // console.log()
        postdata.takeProductAddressId = this.wiliuChose // 地址id
      }
      // console.log(postdata)
      this.loading = true
      // console.log(this.posturl)
      http.post('/mall-order/order/resaleGenerateOrder', postdata, true)
        .then(res => {
          console.log(res)
          if (res.data.code === 200) {
            let str = ''
            let payAmount = 0
            const payStr = '￥'
            str = res.data.data.orderSn
            payAmount += res.data.data.payAmount
            const datecr = res.data.data.createTime
            setStore('settleAccTime', moment(datecr).add(1, 'days').format('YYYY-MM-DD HH:mm:ss'))
            this.$router.push(
              {
                path: '/buy/resaleaccounts',
                query: {
                  orderSn: encodeURIComponent(str),
                  payAmount: encodeURIComponent(JSON.stringify(payStr + payAmount))
                }
              }
            )
            this.shopdata.pmsResaleProduct.stock -= this.quantity.value
          } else {
            ElMessage.error({
              message: res.data.msg
            })
          }
          this.loading = false
          loadingpage.close()
        }).catch(err => {
          console.log(err)
          this.loading = false
          loadingpage.close()
        })
    },
    getzhuanshouMes (mes) { // 处理转售数据方法
      // console.log(mes)
      mes.pmsSkuStock.spData = JSON.parse(mes.pmsSkuStock.spData)
      this.timeYushow = JSON.parse(decodeURIComponent(this.$route.query.time)) // 预提日期
      this.wuliumessage = mes.logisticsVos // 收货地址信息
      this.invoicemessage = mes.invoiceVos // 发票信息
      this.quantity.value = mes.number // 选择了几个
      this.quantity.max = mes.pmsResaleProduct.stock || 0// 最多可买=>转售数量
      this.quantity.min = mes.pmsProduct.minBuy // 最少买
      this.quantity.step = mes.pmsProduct.minIncr // 增量
    },
    readContract (code) {
      const fileUrl = code === 1
        ? 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/contract/%E6%98%86%E5%B1%B1%E5%92%96%E5%95%A1-%E7%8E%B0%E8%B4%A7%E8%B4%AD%E9%94%80%E5%90%88%E5%90%8C.pdf'
        : code === 2
          ? 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/contract/%E6%98%86%E5%B1%B1%E5%92%96%E5%95%A1-%E8%BD%AC%E5%94%AE%E8%B4%AD%E9%94%80%E5%90%88%E5%90%8C.pdf'
          : code === 3
            ? 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/contract/%E6%98%86%E5%B1%B1%E5%92%96%E5%95%A1-%E9%A2%84%E5%94%AE%E8%B4%AD%E9%94%80%E5%90%88%E5%90%8C.pdf'
            : code === 4
              ? 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/contract/%E6%98%86%E5%B1%B1%E5%92%96%E5%95%A1-%E8%B4%B8%E6%98%93%E7%BB%86%E5%88%99.pdf'
              : 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/contract/%E6%98%86%E5%B1%B1%E5%92%96%E5%95%A1-%E8%B4%A8%E9%87%8F%E6%A0%87%E5%87%86.pdf'
      window.open(fileUrl)
    }
  },
  created () {
    this.shopdata = JSON.parse(getStore('resellOrderMessage'))
    console.log(this.shopdata)
    this.getzhuanshouMes(this.shopdata)
  },
  mounted () {
    emitter.on('wuliumesArea', (data) => {
      // console.log(data)
      this.wiliuChose = data
    })
    emitter.on('invoiceMsg', (data) => {
      // console.log(data)
      this.invoiceChose = data
      // console.log(this.invoiceChose)
    })
    // this.shopdata = JSON.parse(this.$route.params.prodata)
  },
  setup (props, ctx) {
    const invoiceGoData = ref('')

    // console.log(route.query.data)

    return {
      invoiceGoData,
      headernav
    }
  }
}
</script>

<style lang="scss">
@import '../../style/viriables.scss';

#page{
  background: #F2EFED;
  width: 100%;
  // height: 100%;
  .nav{
    // height: 30px;
    width: 100%;
    background: $divbgColor;
    ul{
      display: block;
      line-height: 30px;
      width: 1200px;
      margin: 0 auto;
      height: 30px;
      li{
        float: left;
        display: flex;
        height: 30px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
    #search{
      width: 100%;
      height: 120px;
      background: #ffffff;
      .logosearch{
        display: flex;
        width: 1200px;
        margin: 0 auto;
        height: 100%;
        z-index: 99;
        justify-content: left;
        align-items: center;
        #logo{
          img{
            margin-right: 80px;
            z-index: 9999;
          }
        }
      }
    }
  }
  #content{
    margin-top: 10px;
    .message_body{
      margin: 0 auto;
      padding: 20px;
      background: $divbgColor;
      width: 1200px;
      margin-bottom: 10px;
      box-sizing: border-box;
      h1{
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        box-sizing: border-box;
      }
      .show_mess{
        .mess_title{
          padding: 20px;
          .show_ul{
            width: 100%;
            height: 50px;
            background: #F7F7F7;
            display: flex;
            align-items: center;
            .li_name{
              width: 420px;
            }
            .li_size{
              width: 176px;
            }
            .li_price{
              width: 160px;
            }
            .li_quantity{
              width: 240px;
            }
            .li_total{
              flex: 1;
            }
          }
        }
        .shop_mess{
          padding: 20px;
          padding-top: 0;
          border-bottom: 1px solid #EAEAEA;
          .show_ul{
            // padding: 20px 0;
            // box-sizing: border-box;
            display: flex;
            align-items: center;
            .li_name{
              width: 420px;
              .namebody{
                display: flex;
                align-items: center;
                img{
                  height: 80px;
                  width: 80px;
                }
                .name_time{
                  margin-left: 10px;
                  p{
                    width: 310px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    line-height: 39px;
                    color: #333333;
                  }
                  span{
                    color: #999999;
                  }
                }
              }
            }
            .li_size{
              width: 176px;
            }
            .li_price{
              width: 160px;
            }
            .li_quantity{
              width: 240px;
            }
            .li_total{
              flex: 1;
            }
          }
        }
        .shop_type{
          padding: 0 20px;
          display: flex;
          border-bottom: 1px solid #EAEAEA;
          .type_title{
            width: 120px;
            font-size: 14px;
            line-height: 40px;
            color:#999999;
          }
          .radios{
            margin-right: 60px;
          }
          .setime{
            display: flex;
            align-items: center;
            .yutitime{
              width: 100px;
              color: #333333;
            }
            .timeshow{
              // flex: 1;
              .el-input{
                border: none;
                background: 0;
              }
              .showyuti{
                margin-right: 20px;
              }
            }
          }
        }
        .shop_site{
          padding-bottom: 20px;
          border-bottom: 1px solid #EAEAEA;
        }
        .shop_invoice{
          border-bottom: 1px solid #EAEAEA;
        }
        .tishi{
          // height: 50px;
          padding: 0 20px;
          p{
            line-height: 50px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #C6824E;
            display: flex;
            align-items: center;
            span{
              margin-left: 5px;
            }
          }
          .tiaoyue{
            .tiaokuan{
              color: #C6824E;
            }
          }
        }
      }
    }
    .total_price{
      margin: 0 auto;
      padding: 20px;
      background: $divbgColor;
      width: 1200px;
      box-sizing: border-box;
      margin-bottom: 40px;
      .price_body{
        .price_title{
          padding-left: 20px;
          padding-bottom: 10px;
          line-height: 39px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #C6824E;
          border-bottom: 1px solid #EAEAEA;
        }
        .price_show{
          padding: 20px;
          padding-top: 10px;
          // width: 260px;
          display: block;
          .el-descriptions{
            max-width: 800px;
            .el-descriptions__body{
              background: none;
              tr{
                .el-descriptions__cell{
                  display: flex;
                  font-size: 14px;
                  padding-bottom: 6px;
                  justify-content: center;
                  .el-descriptions__label{
                    text-align: justify;
                    text-align-last:justify;/* ie9*/
                    -moz-text-align-last:justify;/*ff*/
                    -webkit-text-align-last:justify;/*chrome 20+*/
                    width: 120px;
                    color: #999999;
                    margin: 0;
                    text-align: justify;
                  }
                  .el-descriptions__content{
                    flex: 1;
                    color: #C6824E;
                    .phonenumshow{
                      color: #333333;
                      span{
                        cursor: pointer;
                        color: #999999;
                      }
                    }
                    span{
                      margin-left: 20px;
                    }
                  }
                }
              }
            }
          }
          .btn_submit{
            float: right;
            .el-button--medium{
              z-index: 99;
              float: right;
              margin-top: -20px;
              background: linear-gradient(0deg, #280E01 0%, #673000 100%);
              span{
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FEFEFE;
              }
              .el-icon{
                color: #FEFEFE;
              }
            }
          }
        }
      }
    }
  }
  #foot{
    background: #280E01;
  }
}
</style>
